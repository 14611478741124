@font-face {
    font-family: 'WindowsRegular';
    src: url('./WindowsRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'WindowsRegular';
    src: url('./WindowsRegular.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  * {
    font-family: 'WindowsRegular', sans-serif !important;
  }