#root{
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    overflow: hidden;
}

html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

.activeWindow{
    z-index: 500 !important
}

.maximizedWindow{
  transform: translate(0px, 0px) !important
}

.cardanoMovement {
    
        position: absolute;
        animation-name: animate;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        /* animation-delay: .5s; */
    
}
@keyframes animate {
    0% {
     left: 32px;
     right: 100%;
   }
   100% {
     left: 75%;
     /* right: 32px; */
     right: 32px
   }
 }