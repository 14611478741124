.desktop-icon {
width: 48px;
height: 48px
}

.desktop-icon-container{
    margin: 32px 32px 0 32px
}

/* .taskbar::-webkit-scrollbar-track {
    background-color: transparent !important;
  }
  .taskbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
  } */
  .taskbar{
    overflow: overlay; 
    scrollbar-width: none;

  }
  .taskbar::-webkit-scrollbar {
    width: 0;
    height: 0;
    scrollbar-width: none;

  }

@media screen and (max-width: 1024px){
    .desktop-icon {
        width: 48px;
        height: 48px
        }
        .desktop-icon-container{
            margin: 16px 16px 0 16px
        }
}

